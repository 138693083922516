import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Integration]';

export const IntegrationActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  FETCH_INTEGRATION: `${ACTION_PREFIX} Fetch Integration`,
  FETCH_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Fetch Integration Success`,
  FETCH_INTEGRATION_FAILED: `${ACTION_PREFIX} Fetch Integration Failed`,

  FETCH_ENHANCED_INTEGRATION: `${ACTION_PREFIX} Fetch Enhanced Integration`,
  FETCH_ENHANCED_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Fetch Enhanced Integration Success`,
  FETCH_ENHANCED_INTEGRATION_FAILED: `${ACTION_PREFIX} Fetch Enhanced Integration Failed`,

  SAVE_INTEGRATION_MAPPING: `${ACTION_PREFIX} Save Integration Mapping`,
  SAVE_INTEGRATION_MAPPING_SUCCESS: `${ACTION_PREFIX} Save Integration Mapping Success`,
  SAVE_INTEGRATION_MAPPING_FAILED: `${ACTION_PREFIX} Save Integration Mapping Failed`,

  UPDATE_INTEGRATION: `${ACTION_PREFIX} Update Integration`,
  UPDATE_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Update Integration Success`,
  UPDATE_INTEGRATION_FAILED: `${ACTION_PREFIX} Update Integration Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,

  AUTHENTICATE_INTEGRATION: `${ACTION_PREFIX} Authenticate Integration`,
  AUTHENTICATE_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Authenticate Integration Success`,
  AUTHENTICATE_INTEGRATION_FAILED: `${ACTION_PREFIX} Authenticate Integration Failed`,

  CONFIGURE_INTEGRATION: `${ACTION_PREFIX} Configure Integration`,
  CONFIGURE_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Configure Integration Success`,
  CONFIGURE_INTEGRATION_FAILED: `${ACTION_PREFIX} Configure Integration Failed`,

  CONFIGURE_DATAFOX_INTEGRATION: `${ACTION_PREFIX} Configure Datafox Integration`,
  CONFIGURE_DATAFOX_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Configure Datafox Integration Success`,
  CONFIGURE_DATAFOX_INTEGRATION_FAILED: `${ACTION_PREFIX} Configure Datafox Integration Failed`,

  UPDATE_DATAFOX_INTEGRATION: `${ACTION_PREFIX} Update Datafox Integration`,
  UPDATE_DATAFOX_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Update Datafox Integration Success`,
  UPDATE_DATAFOX_INTEGRATION_FAILED: `${ACTION_PREFIX} Update Datafox Integration Failed`,

  REQUEST_INTEGRATION: `${ACTION_PREFIX} Request Integration`,
  REQUEST_INTEGRATION_SUCCESS: `${ACTION_PREFIX} Request Integration Success`,
  REQUEST_INTEGRATION_FAILED: `${ACTION_PREFIX} Request Integration Failed`,

  SEND_INTEGRATION_REPORT: `${ACTION_PREFIX} Send Integration Report`,
  SEND_INTEGRATION_REPORT_SUCCESS: `${ACTION_PREFIX} Send Integration Report Success`,
  SEND_INTEGRATION_REPORT_FAILED: `${ACTION_PREFIX} Send Integration Report Failed`,
};

export class IntegrationAction {
  static load(): Action {
    return {
      type: IntegrationActionType.LOAD,
    };
  }

  static loadSuccess(apps): Action {
    return {
      type: IntegrationActionType.LOAD_SUCCESS,
      payload: apps,
    };
  }

  static loadFailed(err): Action {
    return {
      type: IntegrationActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static fetchEnhancedIntegration(id: string): Action {
    return {
      type: IntegrationActionType.FETCH_ENHANCED_INTEGRATION,
      payload: id,
    };
  }

  static fetchEnhancedIntegrationSuccess(apps): Action {
    return {
      type: IntegrationActionType.FETCH_ENHANCED_INTEGRATION_SUCCESS,
      payload: apps,
    };
  }

  static fetchEnhancedIntegrationFailed(id: string, err): Action {
    return {
      type: IntegrationActionType.FETCH_ENHANCED_INTEGRATION_FAILED,
      payload: {
        id,
        ...err,
      },
    };
  }

  static fetchIntegration(id: string): Action {
    return {
      type: IntegrationActionType.FETCH_INTEGRATION,
      payload: id,
    };
  }

  static fetchIntegrationSuccess(integration): Action {
    return {
      type: IntegrationActionType.FETCH_INTEGRATION_SUCCESS,
      payload: integration,
    };
  }

  static fetchIntegrationFailed(id: string, err): Action {
    return {
      type: IntegrationActionType.FETCH_INTEGRATION_FAILED,
      payload: {
        id,
        ...err,
      },
    };
  }

  static saveIntegrationMapping(id: string): Action {
    return {
      type: IntegrationActionType.SAVE_INTEGRATION_MAPPING,
      payload: id,
    };
  }

  static saveIntegrationMappingSuccess(integration): Action {
    return {
      type: IntegrationActionType.SAVE_INTEGRATION_MAPPING_SUCCESS,
      payload: integration,
    };
  }

  static saveIntegrationMappingFailed(id: string, err): Action {
    return {
      type: IntegrationActionType.SAVE_INTEGRATION_MAPPING_FAILED,
      payload: {
        id,
        ...err,
      },
    };
  }

  static updateIntegration(id: string): Action {
    return {
      type: IntegrationActionType.UPDATE_INTEGRATION,
      payload: id,
    };
  }

  static updateIntegrationSuccess(apps): Action {
    return {
      type: IntegrationActionType.UPDATE_INTEGRATION_SUCCESS,
      payload: apps,
    };
  }

  static updateIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.UPDATE_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static add(integrationData): Action {
    return {
      type: IntegrationActionType.ADD,
      payload: integrationData,
    };
  }

  static addSuccess(integrationResponse): Action {
    return {
      type: IntegrationActionType.ADD_SUCCESS,
      payload: integrationResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: IntegrationActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(id, integrationData): Action {
    return {
      type: IntegrationActionType.UPDATE,
      payload: integrationData,
    };
  }

  static updateSuccess(integrationResponse): Action {
    return {
      type: IntegrationActionType.UPDATE_SUCCESS,
      payload: integrationResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: IntegrationActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static deactivate(id): Action {
    return {
      type: IntegrationActionType.DEACTIVATE,
      payload: id,
    };
  }

  static deactivateSuccess(id): Action {
    return {
      type: IntegrationActionType.DEACTIVATE_SUCCESS,
      payload: id,
    };
  }

  static deactivateFailed(id): Action {
    return {
      type: IntegrationActionType.DEACTIVATE_FAILED,
      payload: id,
    };
  }

  static activate(id): Action {
    return {
      type: IntegrationActionType.ACTIVATE,
      payload: id,
    };
  }

  static activateSuccess(id): Action {
    return {
      type: IntegrationActionType.ACTIVATE_SUCCESS,
      payload: id,
    };
  }

  static activateFailed(id): Action {
    return {
      type: IntegrationActionType.ACTIVATE_FAILED,
      payload: id,
    };
  }

  static authenticateIntegration(): Action {
    return {
      type: IntegrationActionType.AUTHENTICATE_INTEGRATION,
    };
  }

  static authenticateIntegrationSuccess(data): Action {
    return {
      type: IntegrationActionType.AUTHENTICATE_INTEGRATION_SUCCESS,
      payload: data,
    };
  }

  static authenticateIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.AUTHENTICATE_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static configureIntegration(): Action {
    return {
      type: IntegrationActionType.CONFIGURE_INTEGRATION,
    };
  }

  static configureIntegrationSuccess(data): Action {
    return {
      type: IntegrationActionType.CONFIGURE_INTEGRATION_SUCCESS,
      payload: data,
    };
  }

  static configureIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.CONFIGURE_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static configureDatafoxIntegration(): Action {
    return {
      type: IntegrationActionType.CONFIGURE_DATAFOX_INTEGRATION,
    };
  }

  static configureDatafoxIntegrationSuccess(data): Action {
    return {
      type: IntegrationActionType.CONFIGURE_DATAFOX_INTEGRATION_SUCCESS,
      payload: data,
    };
  }

  static configureDatafoxIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.CONFIGURE_DATAFOX_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static updateDatafoxIntegration(): Action {
    return {
      type: IntegrationActionType.UPDATE_DATAFOX_INTEGRATION,
    };
  }

  static updateDatafoxIntegrationSuccess(data): Action {
    return {
      type: IntegrationActionType.UPDATE_DATAFOX_INTEGRATION_SUCCESS,
      payload: data,
    };
  }

  static updateDatafoxIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.UPDATE_DATAFOX_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static requestIntegration(): Action {
    return {
      type: IntegrationActionType.REQUEST_INTEGRATION,
    };
  }

  static requestIntegrationSuccess(data): Action {
    return {
      type: IntegrationActionType.REQUEST_INTEGRATION_SUCCESS,
      payload: data,
    };
  }

  static requestIntegrationFailed(err): Action {
    return {
      type: IntegrationActionType.REQUEST_INTEGRATION_FAILED,
      payload: err,
    };
  }

  static sendIntegrationReport(data): Action {
    return {
      type: IntegrationActionType.SEND_INTEGRATION_REPORT,
      payload: data,
    };
  }

  static sendIntegrationReportSuccess(): Action {
    return {
      type: IntegrationActionType.SEND_INTEGRATION_REPORT_SUCCESS,
    };
  }

  static sendIntegrationReportFailed(err): Action {
    return {
      type: IntegrationActionType.SEND_INTEGRATION_REPORT_FAILED,
      payload: err,
    };
  }
}
