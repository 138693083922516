import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { ApiUserSchema, IntegrationSchema } from '../../shared/schemas';

@Injectable()
export class IntegrationApi {
  private endpoint = 'integrations/';

  constructor(private gateway: ApiGateway) {}

  /**
   * Load all the api user data call
   *
   * @returns {Observable<any>}
   */
  load(): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.get(this.endpoint, { params: params }).pipe(
      map((res) => reformatListResponse('Integration', res)),
      map((data) => normalize(data, arrayOf(IntegrationSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  loadApps(): Observable<any> {
    return this.gateway.get(this.endpoint + 'apps').pipe(map((res) => reformatListResponse('App', res)));
  }

  getApiOAuthUrl(appId: number) {
    const params = { api_app_id: appId };

    return this.gateway.get(this.endpoint + 'oauth_login_url', { params: params });
  }

  fetchIntegration(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + id);
  }

  fetchEnhancedIntegration(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + 'map/' + id, { observe: 'response' });
  }

  saveIntegrationMapping(data): Observable<any> {
    return this.gateway.post(this.endpoint + 'map', data).pipe(
      map((res) => reformatEntityResponse('Integration', res)),
      map((data) => normalize(data, IntegrationSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  updateIntegration(data): Observable<any> {
    return this.gateway.put(this.endpoint + data.id, data).pipe(
      map((res) => reformatEntityResponse('Integration', res)),
      map((data) => normalize(data, IntegrationSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  authenticateIntegration(data): Observable<any> {
    return this.gateway.post(this.endpoint + 'authenticate', data);
  }

  configureIntegration(data): Observable<any> {
    return this.gateway.post(this.endpoint, data);
  }

  configureDatafoxIntegration(data): Observable<any> {
    return this.gateway.post('api_users', data);
  }

  updateDatafoxIntegration(data): Observable<any> {
    return this.gateway.put('api_users/' + data.id, data);
  }

  fetchDatafoxIntegration(id): Observable<any> {
    return this.gateway.get('api_users/' + id);
  }

  requestIntegration(data): Observable<any> {
    return this.gateway.post(this.endpoint + 'request', data);
  }

  sendIntegrationReport(id, data): Observable<any> {
    return this.gateway.post(this.endpoint + id + '/trigger', data);
  }

  /**
   * Add api user call
   *
   * @param apiUserData
   * @returns {Observable<any>}
   */
  add(apiUserData): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.post('api_users', apiUserData, { params: params }).pipe(
      map((res) => reformatEntityResponse('ApiUser', res)),
      map((data) => normalize(data, ApiUserSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  /**
   * Update an api user
   *
   * @param {string} id
   * @param apiUserData
   * @returns {Observable<any>}
   */
  update(id: string, apiUserData): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.put('api_users/' + id, apiUserData, { params: params }).pipe(
      map((res) => reformatEntityResponse('ApiUser', res)),
      map((data) => normalize(data, ApiUserSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  /**
   * Deactivate an api user
   *
   * @param {string} id
   * @returns {Observable<any>}
   */
  deactivate(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + id + '/deactivate');
  }

  /**
   * Activate an api user
   *
   * @param {string} id
   * @returns {Observable<any>}
   */
  activate(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + id + '/activate');
  }
}
