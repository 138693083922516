import { UnsafeAction, UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[IntegrationApp]';

export const integrationAppActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,
};

export class IntegrationAppAction {
  static load(): Action {
    return {
      type: integrationAppActionType.LOAD,
    };
  }

  static loadSuccess(integrationApps): UnsafeAction {
    return {
      type: integrationAppActionType.LOAD_SUCCESS,
      payload: integrationApps,
    };
  }

  static loadFailed(err): Action {
    return {
      type: integrationAppActionType.LOAD_FAILED,
      payload: err,
    };
  }
}
