import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { normalize } from 'normalizr';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { canViewIntegrationReport, getPermissionState } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { assignSchemaEntity } from '../../shared/assign';
import { filterDeleted, mapAndSortEntities, reformatEntityResponse } from '../../shared/entity.helper';
import { IntegrationSchema } from '../../shared/schemas';
import { IntegrationAppAction } from '../integration-apps/integration-app.action';
import { IntegrationAppTitle } from '../integration-apps/integration-app.model';
import { IntegrationAction } from './integration.action';
import { IntegrationApi } from './integration.api';
import { IntegrationModel } from './integration.model';

@Injectable()
export class IntegrationService {
  constructor(
    private store: Store<AppState>,
    private api: IntegrationApi,
  ) {}

  load() {
    this.store.dispatch(IntegrationAction.load());

    return this.api.load().pipe(
      tap((response) => this.store.dispatch(IntegrationAction.loadSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public loadApps() {
    return this.api.loadApps().pipe(
      tap((response) => {
        this.store.dispatch(IntegrationAppAction.loadSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(IntegrationAppAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public getOAuthLoginUrl(appId: number) {
    return this.api.getApiOAuthUrl(appId);
  }

  updateIntegration(data) {
    this.store.dispatch(IntegrationAction.updateIntegration(data.id));

    return this.api.updateIntegration(data).pipe(
      tap((response) => this.store.dispatch(IntegrationAction.updateIntegrationSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.updateIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetchIntegration(id: string) {
    this.store.dispatch(IntegrationAction.fetchIntegration(id));

    return this.api.fetchIntegration(id).pipe(
      map((response) => {
        this.store.dispatch(IntegrationAction.fetchIntegrationSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.fetchIntegrationFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  fetchEnhancedIntegration(id: string) {
    this.store.dispatch(IntegrationAction.fetchEnhancedIntegration(id));

    return this.api.fetchEnhancedIntegration(id).pipe(
      tap((response: HttpResponse<any>) => {
        if (response.status !== 200) {
          return;
        }
        const data = reformatEntityResponse('Integration', response.body.data);
        const normalizedData = normalize(data, IntegrationSchema, { assignEntity: assignSchemaEntity });
        this.store.dispatch(IntegrationAction.fetchEnhancedIntegrationSuccess(normalizedData));
      }),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.fetchEnhancedIntegrationFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  saveIntegrationMapping(data) {
    const id = data.Integration.id;

    this.store.dispatch(IntegrationAction.saveIntegrationMapping(id));

    return this.api.saveIntegrationMapping(data).pipe(
      tap((response) => this.store.dispatch(IntegrationAction.saveIntegrationMappingSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.saveIntegrationMappingFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(integrationUserData) {
    if (integrationUserData.id) {
      return this.update(integrationUserData.id, integrationUserData);
    }

    const { id, ...payload } = integrationUserData;

    return this.add(payload);
  }

  add(integrationUserData): Observable<any> {
    this.store.dispatch(IntegrationAction.add(integrationUserData));

    return this.api.add(integrationUserData).pipe(
      tap((response) => this.store.dispatch(IntegrationAction.addSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id: string, integrationUserData) {
    this.store.dispatch(IntegrationAction.update(id, integrationUserData));

    return this.api.update(id, integrationUserData).pipe(
      tap((response) => this.store.dispatch(IntegrationAction.updateSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  deactivate(id: string) {
    this.store.dispatch(IntegrationAction.deactivate(id));

    return this.api.deactivate(id).pipe(
      tap(() => this.store.dispatch(IntegrationAction.deactivateSuccess(id))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.deactivateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  activate(id: string) {
    this.store.dispatch(IntegrationAction.activate(id));

    return this.api.activate(id).pipe(
      tap(() => this.store.dispatch(IntegrationAction.activateSuccess(id))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.activateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  requestIntegration(data) {
    this.store.dispatch(IntegrationAction.requestIntegration());

    return this.api.requestIntegration(data).pipe(
      tap(() => this.store.dispatch(IntegrationAction.requestIntegrationSuccess(data))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.requestIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  authenticateIntegration(data) {
    this.store.dispatch(IntegrationAction.authenticateIntegration());

    return this.api.authenticateIntegration(data).pipe(
      tap(() => this.store.dispatch(IntegrationAction.authenticateIntegrationSuccess(data))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.authenticateIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  configureIntegration(data) {
    this.store.dispatch(IntegrationAction.configureIntegration());

    return this.api.configureIntegration(data).pipe(
      map((response) => {
        this.store.dispatch(IntegrationAction.configureIntegrationSuccess(data));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.configureIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  configureDatafoxIntegration(data) {
    this.store.dispatch(IntegrationAction.configureDatafoxIntegration());

    return this.api.configureDatafoxIntegration(data).pipe(
      tap(() => this.store.dispatch(IntegrationAction.configureDatafoxIntegrationSuccess(data))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.configureDatafoxIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  updateDatafoxIntegration(data) {
    this.store.dispatch(IntegrationAction.updateDatafoxIntegration());

    return this.api.updateDatafoxIntegration(data).pipe(
      tap(() => this.store.dispatch(IntegrationAction.updateDatafoxIntegrationSuccess(data))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.updateDatafoxIntegrationFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetchDatafoxIntegration(id) {
    this.store.dispatch(IntegrationAction.fetchIntegration(id));

    return this.api.fetchDatafoxIntegration(id).pipe(
      tap((response) => this.store.dispatch(IntegrationAction.fetchIntegrationSuccess(response))),
      catchError((response) => {
        this.store.dispatch(IntegrationAction.fetchIntegrationFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  sendIntegrationReport(id: string, data) {
    this.store.dispatch(IntegrationAction.sendIntegrationReport(data));

    return this.api.sendIntegrationReport(id, data).pipe(
      tap(() => this.store.dispatch(IntegrationAction.sendIntegrationReportSuccess())),
      catchError((error) => {
        this.store.dispatch(IntegrationAction.sendIntegrationReportFailed(error));
        return observableThrowError(error);
      }),
    );
  }
}

export const getIntegrationState = (state: AppState) => state.orm.integrations;
export const isIntegrationLoading = (state: AppState) => state.orm.integrations.loading;
export const getActiveIntegrationId = (state: AppState) => state.orm.integrations.activeIntegration;

export const getIntegrationIds = compose((state) => state.items, getIntegrationState);
export const getIntegrationEntities = createSelector(getIntegrationState, (state) => state.itemsById);

export const sortIntegrations = (integrations: IntegrationModel[]) =>
  integrations.sort((a, b) => {
    if (a.deleted && !b.deleted) return 1;
    if (!a.deleted && b.deleted) return -1;
    return a.name.localeCompare(b.name);
  });

export const getIntegrations = createSelector(
  getIntegrationIds,
  getIntegrationEntities,
  mapAndSortEntities(sortIntegrations),
);

export const getActiveIntegrations = createSelector(getIntegrations, (integrations): IntegrationModel[] =>
  integrations.filter((integration) => !integration.deleted),
);

export const getIntegrationType = (title: string) =>
  createSelector(getIntegrations, (integrations) =>
    integrations.filter((integration) => integration.ApiApp?.title === title),
  );
export const getIntegration = (integrationId) =>
  compose((integrationById) => integrationById[integrationId], getIntegrationEntities);

export const getActiveIntegration = createSelector(
  getActiveIntegrationId,
  getIntegrations,
  (activeIntegrationId, integrations) => integrations.find((integration) => integration.id === activeIntegrationId),
);

export const isActiveIntegrationLoading = createSelector(
  getActiveIntegrationId,
  isIntegrationLoading,
  (id, loadingState) => loadingState[id],
);

export const getActiveIntegrationsByType = (integrationType: string) =>
  createSelector(getIntegrationType(integrationType), (integrations) => filterDeleted(integrations));

export const canViewIntegrationTypeReport = (integrationType: string) =>
  createSelector(getActiveIntegrationsByType(integrationType), getPermissionState, (integrations, permissionState) =>
    integrations.some((integration) => canViewIntegrationReport(integration, permissionState)),
  );

export const getIntegrationsWithReportPermissions = (integrationType: IntegrationAppTitle) =>
  createSelector(getPermissionState, getActiveIntegrationsByType(integrationType), (permissionState, integrations) =>
    integrations.filter((integration) => canViewIntegrationReport(integration, permissionState)),
  );
